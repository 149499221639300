import React from "react";
import Info from "./icons/Info";
import Warning from "./icons/Warning";

type IconProps = {
  variant: "info" | "warning";
};

export const CalloutIcon: React.FC<IconProps> = ({ variant }) => {
  switch (variant) {
    case "info":
      return (
        <div className="icon icon--left">
          <Info title={variant} titleId={variant} />
        </div>
      );
    case "warning":
      return (
        <div className="icon icon--left">
          <Warning title={variant} titleId={variant} />
        </div>
      );
    default:
      return null;
  }
};

export const CalloutText: React.FC<{
  children: React.ReactNode;
  testId?: string;
}> = ({ children, testId }) => {
  return (
    <div>
      <span style={{ verticalAlign: "middle" }} data-testid={testId}>
        {children}
      </span>
    </div>
  );
};

type Variant = "info" | "warning" | undefined;

type CalloutProps = {
  children: React.ReactNode;
  bgColor?: string;
  border?: boolean;
  testId?: string;
};

export const Callout: React.FC<CalloutProps> & {
  Icon: typeof CalloutIcon;
  Text: typeof CalloutText;
} = ({ children, testId }) => {
  const variant: Variant =
    Array.isArray(children) && React.isValidElement(children[0])
      ? (children[0] as React.ReactElement<IconProps>).props.variant
      : undefined;

  return (
    <>
      <div data-testid={testId} className={`callout callout--${variant}`}>
        {children}
      </div>
    </>
  );
};

Callout.Icon = CalloutIcon;
Callout.Text = CalloutText;
